/* These styles will be updated to scss as its easier to maintain over time */
/* Global Styles */

html,
body {
    margin: 0;
    overflow-y: auto;
    /* removes vertical scrollbar when not needed */
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

* {
    font-family: 'Inter', sans-serif;
}

#root {
    padding-bottom: 4rem;
}

header {
    margin-bottom: 0.5rem;
}

.page-title {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.375rem;
}

.paradigm-blue {
    background-color: #283342;
}

.btn.btn-primary.dropdown-toggle {
    margin-left: .0625rem;
}

.paradigm-nav-logo {
    height: 2.5625rem;
    width: 7.5625rem;
}

.danger {
    background-color: #A62621 !important;
    border-color: #A62621 !important;
}

.danger:focus {
    box-shadow: 0 0 0.5rem #A62621 !important;
}

.danger:hover {
    background-color: #A62621 !important;
    border-color: #A62621 !important;
}

.paradigm-danger {
    background-color: #A62621 !important;
    border-color: #A62621 !important;
}

.paradigm-hot {
    background-color: #791B17 !important;
    border-color: #791B17 !important;
}

.paradigm-hot::after {
    border-bottom-color: #E05669 !important;
    border-top-color: #E05669 !important;
}

.gray-30 {
    color: #434851;
}

.logout-list {
    padding: .1875rem;
    cursor: pointer;
    color: #FF9800;
    font-weight: bold;
}

/* breadcrumbs */

.breadcrumbs {
    max-width: 75rem;
    margin: 1.25rem 0;
    display: flex;
    align-items: center;

    /* if padding-top is moved to body, it will affect the spacing of the whole page */
    padding-top: 5rem;
    box-sizing: border-box;
}

.crumb {
    display: flex;
    align-items: center;
    margin-right: .625rem;
    font-weight: 500;
}

.breadcrumbs .crumb .separator {
    margin-left: .625rem;
}

/* changes color of last crumb */
.crumb.current {
    color: #2F6CDE;
    font-weight: 700;
}

.breadcrumbs .crumb a {
    color: #434851;
    text-decoration: none;
}

/* Dropdown icon */
.circle {
    display: inline-block;
    border-radius: 50%;
    padding: 0;
    border: none;
}

.circle-inner {
    color: black;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
}

/* Dropdown Menu */

.dropdown-menu {
    --bs-dropdown-link-active-bg: none;
}

.dropdown-item {
    padding: 0;
    margin: 0.5rem 0;
}

/* Dropdown toggles */

.dropdown-toggle.roles {
    border: none;
    background-color: inherit;
}

.dropdown-toggle.logout {
    border: none;
    background-color: inherit;
}

.dropdown-toggle::after {
    border-top: 0.4em solid !important;
    border-right: 0.4em solid transparent !important;
    border-left: 0.4em solid transparent !important;

}

.dropdown-toggle.logout::after {
    border-top: 0.4em solid #D9D9D9 !important;
}

.dropdown-toggle.logout.show::after {
    border-bottom: 0.4em solid #D9D9D9 !important;
    border-top: 0 !important;
    vertical-align: 0.445em;
    transition: all 0.2s ease-in-out;

}


.dropdown-toggle.roles.show::after {
    border-bottom: 0.4em solid !important;
    border-top: 0 !important;
    vertical-align: 0.445em;
    transition: all 0.2s ease-in-out;
}


/* checkbox form inputs */

.form-check {
    padding-left: 2.5em !important;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    padding-right: 7rem;
    margin-bottom: 0 !important;
}

.form-check:hover {
    background-color: #EEF3FC !important;
}

.form-check.checked {
    background-color: #EEF3FC !important;

}

.marker-none {
    display: block;
}

.fw-500 {
    font-weight: 500 !important;
}

.card {
    padding: 1px 1px 0px 1px;
}

/* Table */

table {
    margin-bottom: 0 !important;
    --bs-table-hover-bg: #EEF3FC !important;
}

thead {
    color: #434851 !important;
}

.table>:not(caption)>*>* {
    padding: 1rem;
}

.margin-left-1 {
    margin-left: .75rem
}

.margin-right-1 {
    margin-right: .75rem
}

/* Spinner */

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 15rem);
}

.spinner-wrapper.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 53rem);
}

.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 17rem);
    font-size: 1.5rem;
}

/* Status */

.incomplete-status {
    color: #A62621;
    background: #FCEEED;
    border: solid .0625rem #FFD1CE;
    border-radius: 9999px;
    padding: .0625rem .25rem .0625rem .1875rem;
    text-align: center;
    text-decoration: none;
    font-size: .875rem;
    line-height: 1.125rem;
    font-weight: 500;
    text-transform: capitalize;
}

.complete-status {
    color: #307233;
    background: #EFF8EF;
    border: solid .0625rem #ADDDAF;
    border-radius: 9999px;
    padding: .065rem 0.3rem .065rem 0.3rem;
    text-align: center;
    text-decoration: none;
    font-size: .875rem;
    line-height: 1.125rem;
    font-weight: 500;
    text-transform: capitalize;
}

a.complete-status {
    padding: 0.1rem 0.75rem;
}

a.complete-status:hover {
    color: #307233;
}

.complete {
    padding-right: .375rem;
}

.page-link {
    border: none !important;
    border-radius: 0.375rem;
    color: #1D1F23;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    width: 2.9rem;
}

.disabled {
    pointer-events: none;
}

.page-link:hover {
    color: #1D1F23;
    cursor: pointer;

}

.pagination {
    --bs-pagination-padding-x: .625rem;
    --bs-pagination-padding-y: .75rem;
    margin: 2rem 0;
}

.data-count {
    line-height: 1.5rem;
    color: #434851;
    margin-bottom: 2.5rem;

}

.border-bg-none {
    border: none;
    background-color: inherit;
}

.modal-content {
    padding: .5rem;
}

.create-customer-modal {
    --bs-modal-width: 36.25rem !important;
}

.records_metadata {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
}

.records_metadata.expand {
    max-width: none;
}

tr {
    white-space: nowrap;
}

.nav-link-action>.nav-link-child>a.active {
    border-bottom: 0.25rem solid white;
    padding-bottom: 1.2rem;
}

/* dotted underline for assign customer links */
.dots a {
    color: var(--bs-link-color);
    border-bottom: var(--bs-link-color) .0625rem dotted;
}

/* Off Canvas */

.offcanvas {
    --bs-offcanvas-width: 42.8125rem !important;
    --bs-offcanvas-padding-x: 2rem;
    --bs-offcanvas-padding-y: 2rem;
}

.offcanvas-header {
    padding: 0;
}

.offcanvas-body {
    padding: 0;
    overflow-y: auto;
    padding-bottom: 3rem;

}

.sub-title-canvas {
    padding-top: 0;
    color: #6A7180
}

.sub-title-username {
    font-weight: 700;
    color: #434851;
}


.offcanvas-container {
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    height: 100%;
    position: relative;

}

.mt-5rem {
    margin-top: 5rem;
}

.form-select {
    width: 5.0625rem !important;
    height: 2.25rem !important;
}

.options-select-dropdown {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
    color: #434851 !important;
}

.input {
    display: block;
    width: 100%;
}